@font-face {
  font-family: 'icomoon';
  src:  url('./icomoon.eot?6imxur');
  src:  url('./icomoon.eot?6imxur#iefix') format('embedded-opentype'),
    url('./icomoon.ttf?6imxur') format('truetype'),
    url('./icomoon.woff?6imxur') format('woff'),
    url('./icomoon.svg?6imxur#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-person-digging:before {
  content: "\e926";
}
.icon-bars:before {
  content: "\e927";
}
.icon-github-alt:before {
  content: "\e921";
}
.icon-medium:before {
  content: "\e922";
}
.icon-twitter:before {
  content: "\e923";
}
.icon-x-twitter:before {
  content: "\e924";
}
.icon-itch-io:before {
  content: "\e925";
}
.icon-ellipsis-v:before {
  content: "\e916";
}
.icon-star-half-alt:before {
  content: "\e915";
}
.icon-facebook-brands:before {
  content: "\e900";
}
.icon-linkedin-brands:before {
  content: "\e905";
}
.icon-envelope:before {
  content: "\e913";
}
.icon-phone:before {
  content: "\e914";
}
.icon-comments:before {
  content: "\e909";
}
.icon-star-regular:before {
  content: "\e90a";
}
.icon-thumbtack:before {
  content: "\e90b";
}
.icon-star:before {
  content: "\e90c";
}
.icon-icons-solid1:before {
  content: "\e90d";
}
.icon-pencil-ruler:before {
  content: "\e90e";
}
.icon-icons:before {
  content: "\e90f";
}
.icon-award:before {
  content: "\e910";
}
.icon-images:before {
  content: "\e911";
}
.icon-address-card:before {
  content: "\e912";
}
.icon-github-brands:before {
  content: "\e901";
}
.icon-envelope-open-text:before {
  content: "\e902";
}
.icon-tools:before {
  content: "\e903";
}
.icon-graduation-cap:before {
  content: "\e904";
}
.icon-user:before {
  content: "\e906";
}
.icon-cookie-bite:before {
  content: "\e907";
}
.icon-home:before {
  content: "\e908";
}
.icon-caret-up:before {
  content: "\e91f";
}
.icon-caret-right:before {
  content: "\e91e";
}
.icon-caret-left:before {
  content: "\e91c";
}
.icon-caret-down:before {
  content: "\e91b";
}
.icon-location-arrow:before {
  content: "\e91d";
}
.icon-calendar:before {
  content: "\e920";
}
.icon-arrow-left:before {
  content: "\e917";
}
.icon-arrow-down:before {
  content: "\e918";
}
.icon-arrow-up:before {
  content: "\e919";
}
.icon-arrow-right:before {
  content: "\e91a";
}
.icon-clock:before {
  content: "\e92a";
}
.icon-star-half-stroke:before {
  content: "\e92d";
}
.icon-heart:before {
  content: "\e931";
}
.icon-comments:before {
  content: "\e934";
}
.icon-clipboard-list:before {
  content: "\e935";
}
.icon-tags:before {
  content: "\e938";
}
.icon-tag:before {
  content: "\e939";
}
