/* You can add global styles to this file, and also import other style files */
@use 'assets/style/animations.scss';
@use 'assets/style/theme.scss';
@use 'assets/style/colors.scss';
@use './assets/fonts/icomoon/icomoon.css';
@use './assets/fonts/varela/varela.css';
@import url('https://fonts.googleapis.com/css?family=Lilita+One');              // Font used for titles
@import url('https://fonts.googleapis.com/css?family=Lilita+One|Varela+Round'); // Font used for texts

* {
    box-sizing: border-box;
}

html {
    width: 100%;
    min-height: 100vh;
    background-color: colors.$app-bg-color;
    scroll-behavior: smooth;
}

body {
    width: 100%;
    min-height: 100vh;
    margin: 0;
    padding: 0;
    font-family: 'Varela Round', 'Valera', sans-serif;
}

.page-section {
    padding-top: 40px;
    padding-bottom: 40px;

    &.alternative-color {
        background-color: #6464dc;
        color: colors.$app-white;
    }
}

.iconed-text {
    > span {
        margin-left: 0.42em;
    }
}
