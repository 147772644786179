@use "sass:color";
@use 'colors.scss';

// Titles

@for $i from 1 through 6 {
    h#{$i} {
        max-width: 100vw;
        margin: 0.42em 0;
        font-family: 'Lilita One', cursive;
        font-size: calc( 2.8em - 0.4em * #{$i} );
        font-weight: normal;
    }
}

// Section panels

.page-section {
    &.dark-background {
        background-color: color.adjust(colors.$app-black, $lightness: 5%);
        color: colors.$app-white;
    }

    &.colored-background {
        background-color: colors.$app-primary-color-d;
        color: colors.$app-white;
    }
}

.page-section__main {
    max-width: 60em;
    margin: 0 auto;
}

// Button classes, inspiration from there : https://fdossena.com/?p=html5cool/buttons/i.frag

.role-icon {
    border: 1px solid colors.$app-black;
    border-radius: 50%;
    box-sizing: border-box;
    height: 3rem;
    width: 3rem;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.5rem;
    text-decoration: none !important;

    &.dark-mode {
        border-color: colors.$app-white;
        color: colors.$app-white;
    }
}

.interactable-box {
    border: 1px solid colors.$app-black;
    border-radius: 0.425em;
    box-sizing: border-box;
    box-shadow: 4px 4px colors.$app-black;
    text-decoration: none;
    cursor: pointer;

    &.dark-mode {
        border-color: colors.$app-white;
        box-shadow: 4px 4px colors.$app-white;
        color: colors.$app-white;
    }
}

$btn-height: 2.6em;

a.button, button.button {
    @extend .interactable-box;
    position: relative;
    display: inline-block;
    height: $btn-height;
    padding: calc(#{$btn-height} / 5) calc(#{$btn-height} / 2);
    text-transform: uppercase;
    font-weight: 400;
    color: colors.$app-black;
    background-color: colors.$app-btn-bg-color;
    text-align: center;
}

a.button:hover, button.button:hover {
    background-color: colors.$app-btn-hover-color;
    color: colors.$app-white;
}

@media only screen and (max-width:30em){
    a.button, button.button{
        display:block;
        margin:0.4em auto;
    }
}

.text-xl { font-size: 2rem; }

.theme-text-shadow {
    text-shadow: -1px -1px 0 colors.$header-bg-color, 1px -1px 0 colors.$header-bg-color, -1px 1px 0 colors.$header-bg-color, 1px 1px 0 colors.$header-bg-color, 2px 2px 0 colors.$header-bg-color;
}

// scrollbar
body::-webkit-scrollbar {
    width: 0.75em;
}

body::-webkit-scrollbar-thumb {
    background-color: colors.$app-primary-color-a;
}
