$black: rgb(0, 0, 0);

$app-black: rgb(37, 40, 43);
$app-grey: rgb(117, 120, 123);
$app-white: rgb(247, 250, 253);
$app-bg-color: #F8F7FF;

$app-primary-color-a: #33658A;
$app-primary-color-b: #86BBD8;
$app-primary-color-c: #F8F7FF;
$app-primary-color-d: #AEF78E;
$app-primary-color-e: #C0D461;

$header-bg-color: #000;
$header-text-color: #fff;
$header-text-hover-color: #fff;

$app-btn-bg-color: rgb(247, 250, 253);
$app-btn-hover-color: rgb(91, 114, 162);